import theme_locales from '@theme/locales/en'

export default {
    ...theme_locales,

    language: {
        en:                             "EN",
        'mi-NZ':                        "MI",
    },

    // helpers
    month:                              "month",

    month_name: {
        january:                        "January",
        february:                       "February",
        march:                          "March",
        april:                          "April",
        may:                            "May",
        june:                           "June",
        july:                           "July",
        august:                         "August",
        september:                      "September",
        october:                        "October",
        november:                       "November",
        december:                       "December",

        short: {
            jan:                        "Jan",
            feb:                        "Feb",
            mar:                        "Mar",
            apr:                        "Apr",
            may:                        "May",
            jun:                        "Jun",
            jul:                        "Jul",
            aug:                        "Aug",
            sep:                        "Sep",
            oct:                        "Oct",
            nov:                        "Nov",
            dec:                        "Dec",
        }
    },

    week:                               "week",

    week_day: {
        sunday:                         "Sunday",
        monday:                         "Monday",
        tuesday:                        "Tuesday",
        wednesday:                      "Wednesday",
        thursday:                       "Thursday",
        friday:                         "Friday",
        saturday:                       "Saturday",

        short: {
            sun:                        "Sun",
            mon:                        "Mon",
            tue:                        "Tue",
            wed:                        "Wed",
            thu:                        "Thu",
            fri:                        "Fri",
            sat:                        "Sat",
        },

        min: {
            su:                        "Su",
            mo:                        "Mo",
            tu:                        "Tu",
            we:                        "We",
            th:                        "Th",
            fr:                        "Fr",
            sa:                        "Sa",
        },
    },

    day_part: {
        am :                            "am",
        pm :                            "pm",
    },

    date:                               "Date",

    unknown_error:                      "unknown error",

    // usage
    usage:                              "Usage",
    we_cant_get_usage:                  "We can't get usage data right now",
    refresh_page:                       "Refresh the page",
    domestic:                           "Domestic",
    international:                      "International",

    data:                               "Data",
    voice:                              "Voice",
    international_voice:                "International Voice",
    voice2:                             "NZ/AU Voice calls",

    SMS:                                "SMS",
    international_SMS:                  "International SMS",
    SMS2:                               "NZ/AU SMS",
    sms:                                "SMS",
    
    MMS:                                "MMS",
    MMS2:                               "NZ/AU MMS",
    mms:                                "MMS",

    unit: {
        MB:                             "MB",
        GB:                             "GB",
        mins:                           "mins",
        minutes:                        "minutes",
        SMS:                            "SMS",
        MMS:                            "MMS",
    },

    left_of:                            "left of",

    // common
    sign_in:                            "Sign in",
    sign_in_with:                       "Sign in with",
    proceed_with:                       "Proceed with",
    donot_want_Google_Apple_question:   "Do not want to proceed with Google or Apple login?",
    click_here_to_use_email:            "Click here to use email",
    resolve_issues: {
        base: 'To proceed, you still need to see:',
        and: 'and',
        user: {
            name: 'Name',
            firstname: 'First Name',
            lastname: 'Last Name',

            email: 'Email',
            confirm_email: 'Confirm email',

            password: 'Password',
        },
        accepts: {
            terms: 'Terms & Conditions',
            privacy: 'Privacy Policy',

            acceptance: 'acceptance',
            acceptances: 'acceptances',
        },
    },
    or_sign_in_with:                    "or sign in with",
    
    sign_up:                            "Sign up",
    start_now:                          "Start now",
    sign_up_with:                       "Sign up with",
    or_sign_up_with:                    "or sign up with",
    share_first_and_last_names:         "To continue please share your First and Last names with us in the form below",
    accept_terms_and_conditions:        "To continue please accept the Terms & Conditions and Privacy Policy",
    continue:                           "Continue",

    or:                                 "or",
    proceed:                            "Proceed",
    sign_up_sign_in_with_email_and_password: "Signup / Sign in with email and password",
    no_account_found_create_account_below:   "No account found, create account below",

    log_out:                            "Log out",
    i_do_not_want_to_buy_new_plan:      "I do not want to buy a new plan",

    join:                               "Join",

    first_name:                         "First Name",
    last_name:                          "Last Name",
    full_name:                          "Full Name",
    contact_email:                      "Contact Email",
    contact_email_tooltip:              "This is the email you will receive comms to, not your login email.",
    customer_login_email:               "Customer Login Email",
    e_mail:                             "Email",
    e_mail_address:                     "Email address",
    e_mail_profile:                     "Email",
    enter_an_email:                     "Enter an email",
    enter_your_details_below_to_login:  "Enter your details below to login",
    email_or_mobile_number:             "Email or mobile number",
    enter_their_name:                   "Enter their name",
    route_request:                      "Request",
    route_invite:                       "Invite",
    current_email:                      "Current Email",
    new_email:                          "New Email",
    contact_phone_number:               "Contact Phone Number",
    contact_phone_number_tooltip:       "This is the phone number you will receive comms to, not your login phone number.",
    contact_number:                     "Contact Number",
    mobile_number:                      "Mobile Number",
    mobile_number_lowercase:            "Mobile number",
    or_simid:                           "or SIMID",
    existing_mobile_number:             "Existing Mobile Number",
    existing_number:                    "Existing number",
    old_mobile_number:                  "Old Mobile Number",
    existing_account_number:            "Existing Account Number",
    existing_provider:                  "Existing Provider",
    password:                           "Password",
    invalid_password:                   "Invalid password",
    msisdn:                             "MSISDN",
    sim_id:                             "SIM ID",
    puk_code:                           "PUK Code",
    scan_QR_or_use_data:                "You can scan this QR code using your mobile phone to install eSIM or use the data below to install eSIM manually",
    install_eSIM_manually:              "Or you can use the data below to install eSIM manually",
    confirmation_code:                  "Confirmation Code",
    SMDP_address:                       "SMDP+ Address",
    activation_code:                    "Activation Code (SMDP+ ID)",
    sim_card_number:                    "SIM Card Number",
    account_number:                     "Account Number",
    sms_code:                           "SMS Code",
    username:                           "Username",
    device_name:                        "Device name",
    account_details:                    "Account Details",
    account_actions:                    "Account Actions",
    total_spend:                        "Spend Limit $",
    name:                               "Name",
    cycle:                              "Spend cycle #",
    frequency:                          "Spend cycle timeline",
    total_spend_limit:                  "Total spend limit",
    remaining_spend_limit:              "Remaining spend limit",
    spend_limit_is_over:                "Spend limit is over",
    spend_limit_is_over_error:          "Charge is more than the spend limit{mastername}. Please choose if you want to change your plan or add a payment card",

    my_old_service_is_a:                "My old service is a:",
    pre_pay:                            "Pre-Pay",
    postpay_on_account:                 "Postpay / On-Account",
    old_sim_card_number:                "Old SIM Card Number",
    old_sim_id:                         "Old SIMID",
    check_the_back_of_old_SIM_card:     "Check the back of your old SIM card",
    we_will_begin_to_bring_over_your_number: "We will begin to bring over your number right away.",
    this_can_take_up_to_3_hours:        "This can take up to 3 hours to process and you can track progress in app.",
    your_mobile_number_will_be_brought: "Your mobile number will be brought",
    around_time_on_date:                "around {time} on {date}.",
    OR:                                 "OR",
    schedule:                           "schedule",
    re_schedule:                        "re-schedule",
    click_here_to_schedule_this:        "Click here to {schedule_or_re_schedule} this",
    porting_date_and_time:              "Porting date and time",
    go_back_to_select_date:             "Go back to select date",
    select_time:                        "Select time",
    mobile_number_successfully_ported:  "Please confirm your number port by responding to the confirmation SMS sent to your old SIM. You will need to use your old SIM to receive and respond to this SMS.",
    if_you_wish_to_bring_over_mobile_number: 'If you wish to bring over your current mobile number to your new sim(s),',
    simply_select_bring_my:             'simply select "Bring my',
    selecting_to_bring_over_your_number: 'Selecting to bring over your number will automatically arrange your number be brought over to your new SIM when you activate your new SIM. You can skip this if you wish to do it later.',
    bring_my_number:                    'Bring my number',
    change_details:                     'Change details',
    did_not_get_the_SMS_question:       'Did not get the SMS? -',
    click_here_to_resend:               'Click here to resend',
    wish_to_cancel_request_question:    'Wish to cancel this request? -',
    click_here_to_cancel:               'Click here to cancel',
    number_porting_send_sms_request_success: 'We have sent verification SMS to your number. Please make sure you have responded YES to the verification SMS.',
    number_porting_cancel_success:      'Your number porting request has been successfully canceled.',

    old_password:                       "Old Password",
    new_password:                       "New Password",
    confirm_new_password:               "Confirm New Password",
    create:                             "Create",
    use_different_passwords:            "Please use different passwords for Old and New password fields",
    passwords_dont_match:               '"New password" and "Confirm New Password" do not match',
    please_confirm_new_password:        "Please confirm New Password",

    emails_dont_match:                  '"Email" and "Confirm email" do not match',
    please_confirm_email:               "Please, confirm Email",

    code_is_not_valid:                  "code is not valid",

    re_enter_password:                  "Re-enter Password",
    password_not_match:                 "Password does not match",
    confirm_password:                   "Please confirm Password",
    forgot_password_question:           "Forgot password?",
    email_sent:                         "Email sent",
    we_sent_email_with_password_link:   "We've sent you an email with password reset link. Please check your email box",
    got_it:                             "Got it",
    send:                               "Send",
    resend_in:                          "Resend in",

    phone_number:                       "Phone Number",
    phone_number_lower:                 "Phone number",
    phone_number_or_sim_id:             "Phone Number or SIMID",

    apple:                              "Apple",
    google:                             "Google",

    check_your_email:                   "Check your email",
    enter_the_code_received_via_email:  "Enter the code you received via email.",

    we_will_verify_your_email:          "We will verify your email by sending you an email. Please ensure you use a valid email",
    we_have_sent_to_you_email_confirmation_code: "We have sent you an email to {email} with the email verification code. After receiving the email please enter the code to the form above",
    verification_code_is_incorrect:     "The verification code is incorrect, please use the right code!",
    did_not_receive_it_question:        "Did not receive it?",
    check_your_email_and_click_resend:  'Check your email is correct below and click "re-send"',
    invalid_code_pasted:                "Invalid code pasted",
    resend:                             "Re-send",
    paste:                              "Paste",

    i_accept_the:                       "I accept the",
    terms_and_conditions:               "Terms & Conditions",
    privacy_policy:                     "Privacy Policy",

    already_have_account_question:      "Already have an account?",
    dont_have_account_question:         "Don't have an account?",
    create_account:                     "Create Account",
    i_have_account:                     "I have an account",

    go_back:                            "Go Back",
    new_sim_activation:                 "Please sign-up to begin the activation process for your new SIM",

    per:                                "per",
    buy_now:                            "Buy now",

    error_occurred:                     "An error occurred",
    try_again_later:                    "Please try again later",
    something_went_wrong_try_again:     "Something went wrong. Please try again",
    get_in_touch_with_customer_support: "Please get in touch with customer support",

    switch_account:                     "Switch account",
    submit:                             "Submit",
    accept:                             "Accept",
    cancel:                             "Cancel",
    ok_lets_go:                         "OK - Let's go",
    try_again:                          "Try again",
    we_havent_found_your_phone_number:  "Sorry, we haven't found your Phone number",
    we_havent_found_your_sim_id:        "Sorry, we haven't found your SIM ID",
    service_is_active_with_another_email: "This service is already active with another email",
    skip:                               "Skip",
    do_this_later:                      "Do this later",
    credit_card:                        "Credit Card",

    ok:                                 "Ok",
    use:                                "Use",
    yes:                                "Yes",
    no:                                 "No",
    done:                               "Done",
    dont_show:                          "Dont show",
    enter:                              "Enter",
    no_thanks:                          "No thanks",
    optional:                           "Optional",

    your_payment_was_successful:        "Your payment was successful",
    you_have_successfully_purchased:    "You have successfully purchased",
    addon:                              "addon",
    your_balance_successfully_updated:  "Your balance has successfully been updated",
    insufficient_funds:                 "Insufficient funds",
    not_enough_money_top_up:            "Not enough money to cover the transaction. Please Top-up $",
    more_to_buy_addon:                  "more to buy an addon",
    your_payment_is_unsuccessful:       "Your payment is unsuccessful",
    we_have_problems_with_SIM_activation: "Sorry, we have some problems with SIM activation",
    please_try_again:                   "Please try again",
    try_top_up_again:                   "Try Top-up again",
    you_will_be_redirected_to_sign_in:  "You will be redirected to Sign In page.",
    is_activated_and:                   "plan is activated",
    ready_to_use:                       "and ready to use",
    your_new_mobile_number_is:          "Your new mobile number is",
    view_more_info:                     "View more info",
    your:                               "Your",
    changes:                            "changes",
    profile:                            "profile",
    email:                              "email",
    password_changed:                   "password",
    has_been_successfully:              "has been successfully",
    updated:                            "updated",
    changed:                            "changed",
    change_password:                    "Change Password",
    change_email:                       "Change Email",
    change_login_email:                 "Change Login Email",
    notifications:                      "Notifications",
    notifications_settings:             "Notifications settings",
    notification_options: {
        billing_notification:           'Billing Notification',
        marketing_notification:         'Marketing Notifications',
        product_notification:           'Product Notification',
        all_notification:               'All Notifications',
        invoice_email:                  'Invoice Email',
        low_credit:                     'Low Credit',
        out_of_credit:                  'Out of Credit',
        special_offers:                 'Special Offers',
        newsletters:                    'Newsletters',
        low_allowance:                  'Low Allowance',
    },
    sim_card_actions:                   "SIM Card Actions",
    sim_swap:                           "SIM swap",
    note_by_clicking_eSIM:              "<b>Note:</b> By clicking eSIM{with_password}, we will automatically swap your services over to your new eSIM. You can download your eSIM using the QR code that will follow on screen.",
    note_by_clicking_eSIM_with_password: " and entering in your password",
    if_you_dont_have_a_physical_sim:    "If you don't have a physical SIM, you can order one here",
    if_you_dont_have_esim:              "If you don't have eSIM, you can order one here",
    sim_swap_choose_sim_type:           "To swap your SIM, please choose the SIM type you want to have:",
    eSIM_swap_complete:                 "eSIM Swap Complete",
    use_the_QR_code:                    "Your SIM Swap has been completed. Please use the QR code on screen to download your new eSIM.",
    enter_your_password_below:          "Please enter your password below",
    order_a_SIM_for_SIM_swap:           "Order a SIM for SIM swap",
    order_physical_sim:                 "Click this option if you would like to order yourself a brand new physical SIM to swap to",
    order_esim:                         "Click this option if you would like to order yourself a brand new eSIM to swap to",
    already_have_a_SIM_to_swap_to:      "Click here if you already have a SIM you would like to swap to",
    enter_SIM_ID_for_SIM_swap:          "Enter SIM ID for SIM swap",
    new_simid:                          "Your NEW SIMID",
    sim_swap_error:                     "Please ensure your new SIM is preactivated correctly before trying to swap",
    disable_sim:                        "Disable SIM",
    enable_sim:                         "Enable SIM",
    sim_details:                        "SIM details",
    esim_details:                       "eSIM details",
    get_sim_details:                    "Get my SIM details",
    enter_password_to_view_sim_details: 'Enter your password and click "Confirm" to view your SIM details.',
    click_confirm_to_view_sim_details:  'Click "Confirm" to view your SIM details.',
    generate_eSIM_QR_code:              "Generate eSIM QR Code",
    Product_type_transfer:              "Product type transfer",
    successfully_changed_payment_type:  "We have successfully changed your payment type",
    account:                            "Account",

    save:                               "Save",
    save_changes:                       "Save changes",

    card_number:                        "Card number",
    card_expiry:                        "Card expiry",
    cvc:                                "CVC",

    // validation
    password_must_contain:              "Password must contain 10 or more characters, UPPER & lower case letters and at least one number or symbol",
    ten_or_more_characters:             "10 or more characters",
    upper_and_lower_case_letters:       "UPPER & lower case letters",
    at_least_one_number_or_symbol:      "At least one number or symbol",
    please_enter:                       "Please, enter",
    cant_be_longer_than:                "can't be longer than",
    characters:                         "characters",
    format_is_invalid:                  "format is invalid",
    rule:                               "Rule",
    not_found_in_validator:             "not found in validator",
    sorry_thats_not_a_valid:            "Sorry, that\'s not a valid",
    enter_valid:                        "Please, enter valid",
    current_email_error_message:        "This is your current email. Please enter the new one",

    // base components: app-...
    please_select:                      "please select",
    no_options_to_select:               "No options to select",
    no_results_found_for:               "No results found for",
    view_less:                          "View less",
    view_more:                          "View more",
    plans:                              "Plans",
    about_us:                           "About us",
    about_Us:                           "About Us",
    shop:                               "Shop",
    our_service:                        "Our service",
    our_services:                       "Our services",
    our_Services:                       "Our Services",
    help:                               "Help",
    edit:                               "Edit",
    delete:                             "Delete",
    use_card:                           "Use card",
    mobile:                             "Mobile",
    power:                              "Power",
    my_orders:                          "My Orders",
    eShop:                              "eShop",
    eshop:                              "Eshop",
    payment:                            "Payment",
    delivery:                           "Delivery",
    information:                        "Information",
    confidentiality:                    "Confidentiality",

    manage_your_login_details:          "Manage your login details",
    manage_your_shipping_details:       "Manage your shipping details",
    manage_your_customer_groups:        "Manage your customer groups",
    manage_your_mobile_groups:          "Manage your mobile groups",
    manage_your_eStore_orders:          "Manage your eStore orders",
    manage_your_payment_methods:        "Manage your payment methods",
    manage_your_invoices:               "Manage your invoices",
    manage_your_receipts:               "Manage your receipts",
    view_your_repayment_history:        "View your repayment history",
    coming_soon:                        "Coming soon",

    explore:                            "Explore",
    companions:                         "Companions",
    groups:                             "Groups",
    customer_groups:                    "Customer Groups",
    mobile_groups:                      "Mobile Groups",
    redeem:                             "Redeem",
    redeem_voucher:                     "Redeem Voucher",
    redeem_your_voucher:                "Redeem your voucher",
    your_redeeming_is_unsucessfull:     "Your redeeming is unsucessfull",
    proceed_without_voucher:            "Please try again or proceed without voucher",
    skip_voucher:                       "Skip voucher",

    you_do_not_have_any_product_yet:    "Looks like you do not have any active plans yet!",
    purchase_product:                   "Purchase product",
    choose_another_product:             "Choose another product",
    your_product_is_not_active:         `Your product is not active yet.<br/><br/>${process.env.VUE_APP_NAME?.split(' ')[0] || 'We'} will send you the link via SMS when SIM is installed into your device.<br />Please follow the link from the SMS to activate your product`,
    you_have_inactive_product:          "Your SIM has not yet been activated.<br/><br/>When your SIM arrives, please insert the SIM into your phone,<br/>and follow the welcome link that we send you via SMS.<br/>",
    activate:                           "Activate",
    go_to_activation:                   "I have my SIM - Activate now",
    you_have_no_services_yet:           "You have no {service} services yet",
    purchase_services:                  "Purchase Services",

    // Vuex store
    addon_available_product_not_found:  "Addon available product not found",
    no_response_data:                   "no response data",
    no_products_list:                   "no products list",
    unknown_error_in:                   "unknown error in",
    apple_script_load_error:            "apple auth script load error",
    no_payload:                         "no payload",
    internet:                           "Internet",
    mobile_services:                    "Mobile services",
    entertainment:                      "Entertainment",
    shopping:                           "Shopping",
    gifts:                              "Gifts",
    no_uid:                             "No uid",
    no_user:                            "No User",
    no_user_id:                         "no user ID",
    no_customer_UUID:                   "no customer UUID",
    card_is_not_found:                  "Card is not found",
    payment_is_failed:                  "Payment is failed",
    not_enough_balance:                 "Not enough balance",
    can_not_find_plan:                  "can not find plan",
    you_already_have_this_plan:         "You already have this plan",
    old_password_is_invalid:            "Old password is invalid",
    the_password_is_invalid:            "The password is invalid",
    email_or_password_is_invalid:       "The email address or password is invalid.  Please check your details and try again",
    please_enter_correct_one:           "please enter correct one",
    fair_use_policy_applies:            "Fair use policy applies.",
    current_account_not_specified:      "Current account not specified",
    you_dont_have_prepay_product:       "You don't have prepay product",
    error_by_stripe_loading:            "Error by Stripe loading",

    // view components
    // about
    about_terms_and_conditions:          "Terms & Conditions",
    about_privacy_policy:                "Privacy Policy",
    about_help_and_support:              "Help & Support",

    // home
    see_plans:                          "See plans",

    password_required:                  "Password required",
    enter_pass_to_see_plans:            "Please enter the password to see available plans",

    // 404
    oops:                               "Oops!",
    your_page_not_found:                "Your page wasn’t found",
    back_to_home:                       "Back to Home",

    // onboarding
    // onboarding fwa
    fwa:                                "FWA",

    // onboarding mobile App
    looks_like_you_already_created_login: `Looks like you already created a login for ${process.env.VUE_APP_NAME ?? 'our services'}, please use that instead.`,


    // onboarding mobile flow
    onboarding_mobile_flow: {
        phone_step: {
            title: 'SIM Activation',
            subtitle: 'To complete activation, we need your new SIMID - This is printed on the back of your SIM card. Alternatively, you can also use the NEW phone number of your new SIM to proceed',
        },
        code_step: {
            title: 'Enter Verification Code',
            subtitle: 'Enter the code you received via SMS.',
        },
        success_step: {
            title: 'Great - Thanks!',
            subtitle: 'We have connected that SIM to your account, please click next to continue.',
        },
        error_step: {
            title: 'You entered wrong code!',
            subtitle: 'Please try again.',
        },
        already_associated_step: {
            title: 'This mobile has already been setup',
            subtitle: 'You can sign in to your existing profile using the button below',
        },
    },

    // onboarding postpay
    postpay:                            "Postpay",

    // onboarding prepay
    prepay:                             "Prepay",

    onboarding_prepay: {
        loading: {
            title: 'LOADING...',
            subtitle: '',
        },
        error_activation: {
            title: 'We are having trouble activating your SIM',
            subtitle: 'Please try again.',
        },
        sub_proccess_question: {
            title: 'Are you bringing a mobile number with you?',
            subtitle: '',
        },
        get_prepay_info: {
            title: 'Enter your existing mobile details.',
            subtitle: '',
        },
        by_process: {
            title: 'TODO add BY proccess',
            subtitle: '',
        },
    },

    // onboarding bundle attached
    network_error:                      "Network error",
    continue_to_self_service_portal:    "Click here to go to self-service portal",
    thank_you_for_being_with_app_name:  `Thank you for being with ${process.env.VUE_APP_NAME?.split(' ')[0] || 'us'}!`,
    get_this_plan:                      "Get this plan",
    the_top_up_was_successful:          "The Top-up was successful",
    the_addon_was_successful:           "Activation was successful",
    balance_successfully_funded:        "Your account balance has been successfully funded.",
    addon_activated:                    "You have successfully activated ",
    i_have_a_voucher_code:              "I have a voucher code",
    i_dont_have_a_voucher_code:         "I do not have a voucher code",
    enter_valid_voucher_code:           "Please enter a valid voucher code",
    thirteen_digit_voucher_code:        "13 digit voucher code",

    help_me_choose:                     "Help me choose",

    onboarding_bundle_attached: {
        select_plan_step: {
            title: 'Choose addons',
            subtitle: 'Please select from one of the following addons, to get the best value for money',
            postpay: {
                title: 'Choose your plan',
                help_me_choose_message: 'Postpay mobile includes set plans that give you access to SMS, calling data and more. Postpay plans are billed monthly.',
            },
            prepay: {
                title: 'Choose an addon',
                help_me_choose_message: '@:env_theme.prepay_help_me_choose_message',
            },
            payg: {
                title: 'Choose an addon',
                help_me_choose_message: 'Purchase addons and choose if you want them to recur each month with pay-as-you-go. Simply top up your account with credit/debit card or voucher and use your credit as you wish.',
            },
        },
        redeem_voucher_question: {
            title: 'Do you have a voucher?',
            subtitle: '',
            message: 'Want to see our plans?<br/>Click “I do not have a voucher code”.',
        },
        redeem_voucher: {
            title: 'Redeem your voucher',
            subtitle: 'Enter the voucher code to redeem',
        },
        select_payment_method: {
            title: 'Select billing method',
            subtitle: '',
        },
        credit_checking: {
            title: 'Credit checking',
        },
        authorize_card: {
            title: 'Almost done ',
            subtitle: 'Please wait while we complete authorization.',
        },
        payment_method_card: {
            title: 'Add payment card',
            subtitle: '',
        },
        payment_method_route: {
            title: 'Add new route',
            subtitle: '',
        },
        payment_method_voucher: {
            title: 'Enter your Voucher code',
            subtitle: '',
        },
        activation_page: {
            title: 'Service Activation',
            sim_swap_title: 'Sim swap',
            subtitle: 'Please wait while we activate your service. This can take between 10-30 seconds.',
            warning: 'Do not refresh this page as it may affect your activation',
        },
        number_porting_bringing_over: {
            title: 'Port In Progress',
            subtitle: 'We have already began bringing over your number: <b>{MSISDN_BEING_PORT_IN}</b>',
            info: 'Your temporary number is: <b>{MSISDN}</b>',
            sub_info: 'Please make sure you have responded YES to the verification SMS sent to you when you made the initial request.',
        },
        number_porting_question: {
            title: 'Are you bringing a mobile number with you?',
            subtitle: '',
        },
        get_number_porting_info: {
            title: 'Enter your existing mobile details.',
            subtitle: '',
        },
        mobile_app_download_info: {
            title: 'You’re good to go',
            sim_swap_title: 'Sim Swap Success',
            subtitle: `If you haven’t already, download the ${process.env.VUE_APP_ACTIVATION_PAGE_NAME} app to manage your plan, access monthly bills and view your usage.`,
        },
    },

    we_have_brought_over_your_number:  "We have successfully brought over your number <b>{MSISDN}</b> to your new SIM.",
    if_you_have_trouble_with_connection: "If you have trouble with connection or data, please turn on airplane mode for a few seconds and then turn in back off.",
    sim_swap_issue: "SIM Swap Issue",
    we_had_an_issue_swapping_your_service: "It would seem we had an issue swapping your service to your new SIM. Please get in touch with support to resolve.",


    // onboarding layout
    success:                            "Success",
    fail:                               "Fail",

    // onboarding fail attach service
    next:                               "Next",
    accepted:                           "Accepted",

    onboarding_fail_attach_service: {
        phone_step: 'We are having trouble detecting your phone. Please enter your phone number.',
        code_step: 'Please enter the verification code',
        success_step: 'Great - Thanks, we have connected that mobile to your account',
    },

    // onboarding sub process
    sorry_there_was_a_problem:          "Sorry there was a problem",
    check_your_SIMID:                   "Check Your SIMID",
    how_i_can_find_SIMID:               "How I can find SIMID?",
    verify:                             "Verify",
    change_type:                        "Change type",
    error:                              "Error",
    your_activation_link_expired:       "Your activation link has expired.",
    you_can_receive_new_one:            "You can receive the new one via SMS by pressing the button below",
    receive:                            "Receive",

    onboarding_sub_process: {
        select_type_step: 'Choose, please',
        enter_data_step_prepay: 'Whats your existing number and SIMID',
        enter_data_step_postpay: 'Whats your existing number and account number',
        datachecking_step: 'Perform PreChecks...',
        porting_request_step: 'Submit PortIn request?',
        verify_code_step: 'Please enter the verification code',
    },

    // welcome
    start:                              "Start",

    // help center
    help_center: {
        what_FAQs_do_you_want:          "What FAQs do you want?",
        frequently_asked_questions:     "Frequently Asked Questions",
        FAQ:                            "FAQ",
        FAQs:                           "FAQs",
        back_to_FAQs:                   "Back to FAQs",
        search:                         "Search",
        search_results:                 "Search results:",
        type_your_question:             "Type your question",
        article:                        "article",
        question:                       "Question",
        topics:                         "Topics",
        getting_started:                "Getting Started",
    },

    // plans
    solo: 'Individual',
    no_available_plans:                 "There are no available plans",
    more_details:                       "More details",

    // profile
    // tabs
    your_profile:                       "Profile",

    addons:                             "Addons",
    dashboard:                          "Dashboard",
    current_addons:                     "Current Addons",
    purchase_addons:                    "Purchase Addons",
    you_have_no_addons_yet:             "You have no addons yet",
    no_addons_available:                "No Addons available at this time",
    no_plans_available:                 "No Plans available at this time",
    your_usage:                         "Your Usage",

    your_offers:                        "Your Offers",

    your_balance:                       "Your Balance",
    pay_a_bill:                         "Pay a bill",
    top_up:                             "Top-up",
    change_top_up:                      "Сhanges in payment method",
    new_top_up_text:                    "We can't proceed with payment using bill routing, as it is limited by ${amount}.\n Do you want to continue with your credit card **** {last4}?",
    payment_history:                    "Payment History",
    payment_methods:                    "Payment Methods",
    billing_accounts:                   "Billing Account Links",

    your_bonuses:                       "Your Bonuses",
    your_rewards:                       "Your Rewards",
    other_gifts:                        "Other Gifts",

    broadband:                          "Broadband",
    broadband_subscriptions:            "Existing Subscriptions",
    broadband_setup_new_connection:     "Setup New Connection",

    others:                             "Others",

    // balance
    add_new_payment_method:             "Add new payment method",
    add_new_credit_card:                "Add new credit card",
    link_billing_account:               "Link Billing Account",
    to_remove_this_route:               "To remove this route, please add atleast 1 more valid payment method to your account.",
    to_remove_tooltip:                  "You can do this by clicking “Payment Methods” and then adding a new payment method such as CC.",
    billing_account_request_help_info:  "<b>Request an account</b><br/>Requesting another account means <b>they will pay for your services</b>. Using the below options, you can send them a request which they need to accept before any of your charges will be sent to them.",
    billing_account_invite_help_info: "<b>Invite an account</b><br/>Inviting another account means <b>you wish to pay for their services</b>. They must accept this before your accounts become linked.",
    link:                               "Link",
    unlink:                             "Unlink",
    linked:                             "linked",
    unlinked:                           "unlinked",
    back_to_all_billing_accounts:       "Back to all billing accounts",
    back_to_mobile_groups:              "Back to Mobile Groups",
    accept_new_billing_method:          "Accept",
    reject_new_billing_method:          "Reject",
    delete_route_text:                  "You will no longer have any charges route to yourself for this account",
    delete_route_text_child:            "You will no longer have any charges route to this account",
    new_route_request:                  "New bill pay request",
    add_payment_card:                   "Add payment card",
    add_new_credit_card_details:        "Add new credit card details",
    add_new_route_request:              "You can pay on another account",
    pay_via_other_account:              "Pay Via Other Account",
    edit_billing_account:               "Edit Billing Account",
    add:                                "Add",
    payment_method_has_been_added:      "Your payment method has been added.",
    billing_method_has_been_added:      "We have sent your request",
    billing_method_has_been_updated:    "Your billing route has been updated.",
    billing_route_has_been_accepted:    "Your billing route has been accepted.",
    remove:                             "Remove",
    removed:                            "Removed",
    debit_account:                      "Debit Account",
    credit_card_is_about_to_expire:     "We cannot authorize your credit card as it is about to expire, please use a different credit card",
    payment_successfully_completed:     "Your payment has been successfully completed",
    back_to_all_methods:                "Back to all methods",
    current_balance:                    "Current Balance",
    balance_expires:                    "Balance Expires",
    pay:                                "Pay",
    use_voucher:                        "Use Voucher",
    make_a_payment:                     "Make a payment",
    make_a_payment_upper:               "Make a Payment",
    pay_full_amount_of:                 "Pay full amount of",
    pay_a_custom_amount:                "Pay a custom amount",
    payment_amount:                     "Payment amount",
    enter_amount:                       "Enter amount",
    pay_bill:                           "Pay bill",
    pay_bill_upper:                     "Pay Bill",
    pay_for_your_services:              "Pay for your services",
    select_payment_method:              "Select payment method",
    please_select_a_payment_method:     "Please select a payment method",
    please_enter_an_amount:             "Please enter an amount",
    payment_amount_should_be:           "Payment amount should be",
    default:                            "default",
    expired:                            "Expired",
    we_will_only_charge_the_default_card: "We will only charge the default card",
    you_dont_have_any_payment:          "You don't have any payment",
    you_dont_have_any_billing:          "No billing account link is setup. You are you paying your own bills.",
    you_dont_have_any_billing_requests: "You have no billing account link requests",
    methods_loaded_yet:                 "methods loaded yet",
    top_up_using_credit_card:           "Top-up using Credit Card",
    top_up_by_number:                   "Top-up by number",
    confirm_to_make_a_payment:          'Click "Confirm" to make a payment',
    confirm:                            "Confirm",
    for:                                "for",
    your_payment_has_been_successful:   "Your payment has been successful.",
    the:                                "The",
    selected_card:                      "Selected card",
    expired_add_another_card:           "is expired, please add another card",
    expired_choose_or_add_another_card: "is expired, please choose or add another card",
    please_add_another_card:            "Please add another card",
    please_choose_or_add_another_card:  "Please choose or add another card",
    choose_another_payment_method:      "Please choose another payment method or add a new credit card.",
    existing_methods:                   "Existing methods",
    add_new_card:                       "Add new card",
    payment_method_setup_error:         "Payment method setup error",
    please_enter_payment_method:        "Please, enter the payment method",
    enter_amount_larger_than_0_99:      "Please enter an amount larger than $0.99 NZD",
    under_construction:                 "Under Construction",

    // payment methods
    loading:                            "Loading",
    you_cant_remove_your_only_card:     "You can't remove your only card.",
    please_add_another_one:             "Please add another one first, if you still want to delete",
    set_as_default:                     "Set as Default",
    not:                                "NOT",
    you_have_opted:                     "You have opted to {opt} make this your default payment method",
    default_card_was_changed_to:        "Your default payment card was changed to",
    something_went_wrong:               "Something went wrong, try later please",
    payment_processing_error_title:     "Payment processing error",
    payment_processing_error:           "We have problems with processing the payment. Please choose or add another card",

    // payment history
    load_more:                          "Load more",
    payment_history_is_empty:           "Your payment history is empty",
    download_bill:                      "Download bill",

    // Top-up
    card:                               "Card",
    voucher:                            "Voucher",
    auto:                               "Auto",
    credit_card_top_up:                 "Credit Card Top-up",
    top_up_using_voucher:               "Top-up using a voucher",
    enter_your_voucher_code:            "Please enter your voucher code below",
    auto_top_up:                        "Auto Top-up",
    enable_auto_top_up:                 "Enable Auto Top-up",
    balance_below:                      "Balance below",
    every_x_days:                       "Every X days",
    every_x_number_of_days:             "Every X number of days...",
    enter_valid_number_of_days:         "Please, enter valid number of days (must be 1 - 99)",
    top_up_this_amount:                 "Top-up this amount",
    enter_valid_amount:                 "Please, enter valid amount (must be 1 - 999)",
    top_up_when_below_amount:           "Top-up when below Amount",
    auto_top_up_enabled:                "Auto Top-up has been successfully enabled",
    next_top_up_will_be_in_for:         "Your next Top-up will be in {n} day for ${amount}.00 | Your next Top-up will be in {n} days for ${amount}.00",
    you_will_be_charged_for:            "You will be charged for ${amount}.00 when your balance become less than $1.00",
    top_up_amount_should_be:            "Top-up amount should be",
    confirm_to_top:                     'Click "Confirm" to Top-up your balance',
    your_balance_now_is:                "Your balance now is",
    your_amount_owing_now_is:           "Your amount owing now is",
    your_credit_amount_now_is:          "You credit amount now is",
    recharge_voucher:                   "Recharge voucher",
    voucher_redemption:                 "Voucher Redemption",

    // store
    store:                              "Store",

    // bonuses
    bonuses:                            "Bonuses",
    back_to_all_bonuses:                "Back to all bonuses",
    exchange_for_bonuses:               "Exchange for bonuses",
    accumulated_bonuses:                "Accumulated bonuses",
    activation_cost:                    "Activation cost",
    value:                              "Value",
    validity:                           "Validity",
    redeem_now:                         "Redeem now",
    bonus_points:                       "Bonus points",
    no_available_rewards:               "There are no available rewards",

    // personal data
    personal_data:                      "Personal Data",
    confirmation:                       "Confirmation",
    are_you_sure_question:              "Are you sure?",
    confirm_to_swap_SIM:                'Click "Confirm" to swap your SIM.',
    successfully_swapped_SIM:           "You have successfully swapped your SIM",
    enter_password_to_change_SIM_status: 'Enter your password and click "Confirm" to {status} your SIM.',
    click_confirm_to_change_SIM_status: 'Click "Confirm" to {status} your SIM.',
    we_sent_email_with_validation_link: "We've sent you an email with validation link.",

    manage_customer_groups:             "Manage Customer Groups",
    manage_mobile_groups:               "Manage Mobile Groups",
    getting_groups:                     "Getting groups",
    you_dont_have_any_groups_yet:       "You don't have any groups yet",
    add_new_group:                      "Add New Group",
    retry:                              "Retry",
    back_to_groups:                     "Back to groups",
    group_members:                      "Group members",
    add_new_group_modal:                "Add new group",
    existing_group:                     "Existing group",
    new_group:                          "New group",
    group_code:                         "Group Code",
    group_name:                         "Group Name",
    companion:                          "COMPANION",
    enterprise:                         "ENTERPRISE",
    delete_member:                      "Delete a member",
    are_you_sure_you_want_to_remove:    "Are you sure you want to remove {name}?",
    your_request_is_complete:           "Your request is complete",
    we_have_removed_group_member:       "We have removed {your_or_the} service from the Group.",
    successfully_removed_from_group:    "Successfully removed from group",

    you_havent_invited_members_yet:     "You haven't invited any members yet",
    no_members_in_this_group:           "No members in this group",
    you_dont_have_any_invites_yet:      "You don't have any {action} invites yet",
    type:                               "Type",
    max_group_size:                     "Max Group Size",
    leave_a_group:                      "Leave a group",
    are_you_sure_you_want_to_leave_a_group: "Are you sure you want to leave a group?",
    do_you_want_to_leave_this_group:    "Do you want to leave this group?",
    keep_your_billing_account_is_the_group_owner: "Keep your billing account is the group owner:",
    by_turning_this_off_all_charges_will_be_charged_to_you: "By turning this off, all mobile charges will now be charged to you.",
    if_you_do_not_have_a_payment_method: "If you do not have a payment method on your account, you will need to add one.",
    you_can_also_click_remove:          'You can also click "Remove" to stop paying for the below mobiles.',
    you:                                "You",
    members:                            "Members",
    leader:                             "leader",
    invite_new_member:                  "Invite new member",
    enter_identifier_you_wish_to_invite: "Please enter the phone number, customer ID or email of the person you wish to invite to the group",
    enter_phone_number_of_the_person:   "Please enter the phone number of the person you wish to invite to the group",
    primary_account_tooltip:            "Primary Account will be responsible for all bill payments",
    need_to_order_a_new_sim_question:   "Need to order a NEW SIM for this user?",
    click_here:                         "Click here",
    i_want_to_pay_for_their_mobile:     "I want to pay for their mobile",
    send_invite:                        "Send invite",
    we_have_sent_your_request:          "We have sent your request. If this user is a customer with us, they can accept the invite and join your group.",
    edit_spend_limit:                   "Edit Spend Limit",
    remove_charge_route:                "Remove charge route",
    request_pay_my_bills:               "Request pay my bills",
    invite_pay_bills:                   "Invite pay bills",
    remove_member:                      "Remove Member",
    get_replacement_sim:                "Get replacement SIM",
    what_type_of_SIM_to_replace:        "What type of SIM do you want to replace the existing one with?",
    order_replacement_physical_sim:     "Click this option if you would like to order a brand new physical SIM",
    order_replacement_esim:             "Click this option if you would like to order a brand new eSIM",

    Phone:                              "Phone",
    Email:                              "Email",
    UUID:                               "ID",

    enter_phone_number:                 "Enter phone number",
    enter_member_name:                  "Enter member name",
    member_name:                        "Member Name",
    enter_email:                        "Enter email",
    enter_customer_uuid:                "Enter customer ID",
    please:                             "Please",
    
    customer_uuid:                      "Customer ID",

    invites:                            "Invites",
    incoming_invites:                   "Incoming invites",
    sent_invites:                       "Sent invites",
    incoming:                           "incoming",
    sent:                               "sent",
    pending:                            "pending",
    invite_to:                          "Invite to",

    pending_incoming_invite_info:       '{name} invites you to the group. Do you want to join?',
    accepted_incoming_invite_info:      'You accepted {name}\'s invite to the group.',
    declined_incoming_invite_info:      'You declined {name}\'s invite to the group.',

    pending_sent_invite_info:           '{name} has not accepted your request to join the group yet.',
    accepted_sent_invite_info:          '{name} has accepted your request to join the group.',
    declined_sent_invite_info:          '{name} declined your request to join the group.',

    decline_invitation:                 "Decline invitation",
    cancel_invite:                      "Cancel invite",
    are_you_sure_you_want_to_decline_invitation: "Are you sure you want to decline invitation {from_to} {name}?",
    are_you_sure_you_want_to_cancel_invite: "Are you sure you want to cancel invite {from_to} {name}?",

    you_have_accepted_invitation:       "You have successfully accepted invitation",

    declined:                           "declined",
    decline:                            "Decline",

    shipping_details:                   "Shipping Details",
    delivery_addresses:                 "Delivery addresses",
    hint_delivery_addresses:            "Hint: Please enter the building number and street name then choose an address from the dropdown",
    delivery_addresses_note:            "<b>Please note:</b> We immediately dispatch all orders so that we can get your order to you as fast as possible, as such, updating your address will only take effect for your next order",
    enter_delivery_address:             "Please enter the Delivery address",
    provide_street_city_postcode:       "Please provide at least the street name, city, and postcode",
    default_delivery_was_changed_to:    "Your default delivery address was changed to <b>{default_address_to_change}</b>",
    getting_delivery_addresses:         "Getting delivery addresses",
    dont_have_delivery_addresses:       "You don't have any delivery addresses yet",
    add_new_address:                    "Add new address",
    confirm_removal_of_address:         "Please confirm removal of <b>{delete_address}</b> from your address list",
    address:                            "Address",
    city:                               "City",
    region:                             "Region",

    order_history:                      "Order History",
    getting_order_history:              "Getting order history",
    order_history_is_empty:             "Sorry, but your order history is empty",
    item:                               "item",
    items:                              "items",

    // plans
    you_currently_dont_have_mobile_plan: "You currently don’t have a mobile plan",
    select_a_plan_to_get_started:       "Please select a plan to get started",
    select_plan:                        "Select plan",
    next_payment_and_plan_updating:     "Next payment and plan updating",
    next_plan_will_be_started_on:       'Your next plan is "{plan_name}" will be started on {start_date}',
    this_plan_will_be_started_on:       'This plan will be started on {start_date}',
    cancel_purchase:                    "Cancel purchase",
    confirm_cancelation:                "Confirm cancelation",
    you_want_to_cancel_purchase:        "You want to cancel purchase:",
    click_confirm_to_cancel:            'Please click "Confirm" to cancel this purchase.',
    sorry:                              "Sorry",
    you_do_not_have_enough_balance:     "You do not have enough balance.",
    top_up_first:                       "Please Top-up first.",
    get_this_addon:                     "Get this addon",
    choose_this_addon:                  "Choose this addon",
    please_confirm:                     "Please confirm",
    confirm_purchase_addon:             'You want to purchase the <strong>"{name}"</strong> {is_one_time_or_auto_renuw} {plan_or_addon}, click "Confirm" for purchase.',
    confirm_purchase_addon_non_recurring: 'You are about to purchase <strong>"{name}"</strong>, please confirm below.',
    product_successfully_purchased:     'The <strong>"{purchased_product_name}"</strong> {plan_or_addon} has been successfully purchased.',
    addon_successfully_unsubscribed:    'Subscription to the <strong>"{unsubscribed_addon_name}"</strong> addon has been successfully canceled.',
    please_confirm_change_current_plan: 'Please confirm that you wish to change your current plan to <strong>"{plan_name}"</strong> for <strong>${price}</strong>',
    your_new_plan_will_take_effect_from:'Your new plan <strong>"{plan_name}"</strong> will take effect from <br/><strong>{next_billing_period_date}</strong>',
    your_new_plan_will_take_effect_now: 'Your new plan <strong>"{plan_name}"</strong> will take effect in a few minutes',
    plan_cancelation_will_take_effect:  'Scheduled plan change to <strong>"{plan_name}"</strong> will be canceled in a few minutes',
    you_able_change_plan_once_per_month: "You are only able to change your plan <strong>once per month</strong> and so you cannot retry until: <br/><strong>{next_billing_period_date}</strong>",

    status: {
        low:                            "low",
        active:                         "active",
    },

    to_be_carried:                      "To be carried into the next period for up to 365.",
    throttled_info:                     "You've reached data cap for your plan. It will be full speed again during your next billing period",

    Status:                             "Status",
    Carrier:                            "Carrier",
    Number:                             "Number",

    breakdown:                          "Breakdown",
    details:                            "Details",
    full_month_usage:                   "Full month usage",
    back_to_usage:                      "Back to usage",
    no_current_usage_type_to_show:      "No {current_usage_type} usage to show",

    // select account
    balance:                            "Balance",

    // sim swap
    back_to_profile:                    "Back to profile",
    head_to_profile:                    "Head to profile",
    go_to_order_history:                "Go to Order History",
    please_wait:                        "Please wait. It does not take much time.",
    contact_our_support_team:           "please contact our support team using chat in the right bottom corner or call us 8 800 800 300",
    SIM_swap_is_completed:              "Your SIM swap is completed!",

    // terms
    back_to_terms_and_conditions:       "Back to Terms & Conditions",

    terms_link: {
        monthly_top_up:                 "Monthly Top-up",
        prepay:                         "Prepay",
        packs_and_combos:               "Packs and Combos",
    },

    // Security
    security:                           "Security",
    basic:                              "Basic",
    advanced:                           "Advanced",
    reports:                            "Reports",
    white_blacklist:                    "White/Blacklist",
    basic_mode:                         "Basic Mode",
    advanced_mode:                      "Advanced Mode",
    sites:                              "sites",
    was_blocked:                        "was blocked",
    back_to_mobile:                     "Back to Mobile",

    kids_safe:                          "Kids Safe",
    protect_from_inappropriate_sites:   "Help protect your kids from inappropriate websites",
    all_the_time_lead:                  "all the time:",
    adult_content:                      "Adult Content",
    weapons_violence:                   "Weapons & Violence",
    hacking_sites:                      "Hacking Sites",

    homework_time:                      "Homework Time",
    block_social_networks:              "Block social networks and gaming sites:",
    social_media:                       "Social Media",
    games_and_humor:                    "Games and Humor",
    from:                               "From",
    to:                                 "To",
    monday_to_friday:                   "Monday to Friday",

    prevent_piracy:                     "Prevent Piracy",
    helps_protect_from_malware:         "Helps protect you from Malware and other online security problems",
    antivirus:                          "Antivirus",
    file_sharing_ites:                  "File Sharing Sites",

    category:                           "Category",
    whitelist:                          "Whitelist",
    blacklist:                          "Blacklist",
    list_of_through_domains:            "This is a list of domains that will always be let through",
    list_of_blocked_domains:            "This is a list of domains that will always be blocked",
    domain_name:                        "Domain name",
    enter_domain_name:                  "Enter domain name",
    warning:                            "Warning",
    remove_domain_question:             "Do You really want to remove this domain?",

    all_the_time:                        "All the Time",
    custom_policy:                      "Custom Policy",

    this_policy_applies_from:           "This policy applies from",

    // Onboarding number porting
    number_porting:                     "Number porting",
    customer_name:                      "Customer Name",
    name_on_existing_account:           "Name on existing account",
    porting:                            "porting",

    POTRING_STATES: {
        PORT_REQUESTED: {
            status: 'Awaiting LSP Response',
            tooltip: 'GSP has placed port request',
        },
        PORT_VALIDATED: {
            status: 'Awaiting GSP Approval',
            tooltip: 'LSP has submitted port response and Customer has confirmed port via SMS',
        },
        PORT_APPROVED: {
            status: 'Approved',
            tooltip: 'GSP has approved the port request',
        },
        INTRA_CARRIER_WAIT: {
            status: 'SMS Response Received Awaiting LSP Response',
            tooltip: 'Customer has responded to SMS but LSP has not submitted port response',
        },
        NETWORK_PROVISIONING: {
            status: 'In Progress',
            tooltip: 'Inter carrier port has been activated',
        },
        PORT_COMPLETED: {
            status: 'GC and LC Complete',
            tooltip: 'Gaining and losing carriers have updated port progress',
        },
        PORT_CANCELED: {
            status:  'Cancelled',
            tooltip: 'GSP cancelled the port (before approval)',
        },
        PORT_REJECTED: {
            status: 'Rejected',
            tooltip: 'GSP has rejected port as LSP marked port as either ‘LSP Incorrect’ or ‘Account Number Incorrect’',
        },
        SERVICE_ACTIVATION_COMPLETED: {
            status: 'Service Activation Complete',
            tooltip: 'Service is provisioned and ready to use',
        },
        SERVICE_ACTIVATION_FAILED: {
            status: 'Service Activation FAILED',
            tooltip: 'Port request failed',
        },
    },

    // Recurring Addons
    next_renewal_date:                  "Addon Expires:", // this has to be changed
    purchase_confirmation:              "Purchase confirmation",
    you_want_to_buy_the_addon:          "You want to buy the addon",
    you_want_to_buy_the_plan:           "You want to buy the plan",
    you_can:                            "You can",
    subscribe:                          "Subscribe",
    auto_renew:                         "Auto Renew",
    auto_renew_lower:                   "Auto renew",
    non_recurring:                      "Non-recurring",
    not_auto_renewing_plan:             "This is not an auto-renewing plan",
    turn_on:                            "turn on",
    turn_off:                           "turn off",
    you_want_to_turn_on_auto_renew:     "You want to turn on auto renew on the plan",
    you_want_to_turn_off_auto_renew:    "You want to turn off auto renew from the plan",
    click_confirm_to_proceed:           'click "Confirm" to proceed',
    this_addon:                         "this addon",
    this_plan:                          "this plan",
    we_will_automatically_charge_your_card: "we will automatically charge your card before your {plan_or_addon} expires",
    your_plan_will_expire_at_the_end_of_cycle: "your {plan_or_addon} will expire at the end of cycle",
    this_can_be_turned:                 "this can be turned",
    in_your_app:                        "in your app",
    auto_renew_is:                      "Auto Renew is",
    on:                                 "ON",
    off:                                "OFF",
    as_one_time:                        "as one time",
    as_auto_renew:                      "as auto renew",
    one_time_buy:                       "One time buy",
    replacement_options:                "Replacement options",
    replacement_text_1:                 "You can change plan now, which starts a new plan now and cancels the auto renew on the old plan",
    replacement_text_2:                 "You can change plan at next billing date to apply new plan at the end of the renewal period",
    simply_select_which_product:        "Simply select which product",
    you_wish_to_swap_from:              "you wish to swap from",
    replace_now:                        "Replace now",
    switch_plan_now:                    "Switch plan now",
    switch:                             "Switch",
    you_already_have_addons:            "You already have addons",
    select_subscriptions_to_replace:    "Select the subscriptions you would like to replace",
    view_full_information:              "View full information",
    renews_on:                          "Renews on",
    expires_on:                         "Expires on",
    starts_on:                          "Starts on",
    mobile_daily_roaming:               "Mobile Daily Roaming",
    ends_on:                            "Ends on",
    at:                                 "at",

    // Rename menu items
    home:                               "Home",
    products:                           "Products",
    billing:                            "Billing",

    // Onboarding
    payg_tab:                           "PAYG",
    prepay_tab:                         "PrePay",
    postpay_tab:                        "PostPay",
    purchase_plan:                      "Purchase Plan",
    choose_this_plan:                   "Choose this plan",
    billed_monthly:                     "Billed {months} monthly",

    // Landing page
    plan_types_list: {
        prepay: {
            title: "Prepay",
            dropdown_title: "PrePay",
            lead: "Get amazing value without any term commitments",
            features: {
                great_data_packages:    "Great data packages at low prices",
                bring_existing_number:  "Bring your existing number with you",
                amazing_loyalty_deals:  "Amazing loyalty deals and special offers",
                solid_network:          "A solid network you can depend on",
            },
        },
        postpay: {
            title: "Postpay",
            dropdown_title: "PostPay",
            lead: "Stay connected on our monthly plans",
            features: {
                great_data_packages:    "Great data packages at low prices",
                bring_existing_number:  "Bring your existing number with you",
                amazing_loyalty_deals:  "Amazing loyalty deals and special offers",
                solid_network:          "A solid network you can depend on",
            },
        },
        payg: {
            title: "PAYG",
            dropdown_title: "PAYG",
            lead: "Get amazing value without any term commitments",
            features: {
                great_data_packages:    "Great data packages at low prices",
                bring_existing_number:  "Bring your existing number with you",
                amazing_loyalty_deals:  "Amazing loyalty deals and special offers",
                solid_network:          "A solid network you can depend on",
            },
        },
    },

    // UFB
    ufb:                                "UFB",
    get_started:                        "Get started",
    visit_date:                         "Visit date",
    visit_time:                         "Visit time",
    desired_go_live_date:               "Desired go live date",
    ideal_go_live_date:                 "Ideal go live date",
    payment_method:                     "Payment Method",
    select:                             "Select",
    go_live:                            "Go Live",
    book_install_visit:                 "Book Install Visit",
    choose_the_time_installation:       "Please choose the time that works for you to meet with out engineers to finish installation",
    book:                               "Book",
    thank_you_for_sharing_details:      "Thank you for sharing details with us. Our agent will contact you soon.",
    book_scope_visit:                   "Book Scope Visit",
    choose_the_time_scope:              "Please choose the time that works for you to meet with our engineers to estimate the scope of work",
    order_confirmed:                    "Order confirmed",
    wait_to_hear_from_us:               "Please wait to hear from us",
    multiple_UFB_products:              "Multiple UFB products",
    select_UFB_product:                 "Select UFB product",
    ufb_plan_types_list: {
        broadband: {
            title: "Broadband",
            dropdown_title: "Broadband",
            lead: "Lorem ipsum dolororem ipsum dolor sit amet consectetur.",
            features: {
                great_data_packages:    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem",
                bring_existing_number:  "Accusantium doloremque laudantium",
                amazing_loyalty_deals:  "Totam rem aperiam",
                solid_network:          "Eaque ipsa quae ab illo inventore veritatis et quasi",
            },
        },
    },
    wifi_router:                        "Wi-Fi router",
    bring_my_own_modem:                 "Bring my own Modem",

    // Postpay Products
    my_plan_and_addons:                 "My Plan and Addons",
    current_plans:                      "Current Plans",
    current_plans_and_addons:           "Current Plans and Addons",
    purchase_plans_and_addons:          "Purchase Plans and Addons",
    purchase_plans:                     "Purchase Plans",
    your_next_bill_is_due:              "Your next bill is due:",
    amount_owing:                       "Amount Owing",
    account_credit:                     "Account Credit",

    // Profile
    customer_account_number:            "Customer Account Number",
    id:                                 "ID",
    dark_theme:                         "Dark Theme",
    settings:                           "Settings",

    // Supp features
    sim_settings:                       "SIM Settings",
    international_roaming:              "International Roaming",
    auto_roaming_charge:                "Auto Roaming Charge",
    on_upper:                           "ON",
    off_upper:                          "OFF",
    roaming_help:                       "If this is on, we will automatically charge your account balance for daily roaming charges when you travel overseas. Turning this off means we will require your approval via SMS to activate your daily roaming.",
    
    cellular_features:                  "Cellular Features",
    supplementary_voice_settings:       "Supplementary Voice Settings",
    call_barring:                       "Call Barring",
    multi_party_services:               "Multi Party Services",
    call_waiting:                       "Call waiting",

    call_hold:                          "Call hold",
    allow_call_hold:                    "Allow call hold",
    three_way_conferencing:             "3 Way Conferencing",
    available:                          "Available",
    enabled:                            "Enabled",
    disabled:                           "Disabled",
    swapped:                            "swapped",
    saved:                              "saved",
    successfully_enabled_sim:           "We have successfully enabled your SIM.<br/>Please turn on airplane mode for a few seconds on your phone then turn it back off to enable your SIM.",
    successfully_disabled_sim:          "We have successfully disabled your SIM.",

    block_roaming:                      "Block roaming",
    outgoing_text_barred:               "Outgoing Text Barred",
    block_inbound_calls_while_roaming:  "Block Inbound Calls while Roaming",
    customer_allows_incoming_sms:       "Customer allows Incoming SMS",
    block_outbound_calls_while_roaming: "Block Outbound Calls while Roaming",
    customer_allows_outgoing_sms:       "Customer allows Outgoing SMS",
    block_out_bundle_while_roaming:     "Block Out-of-Bundle calls while Roaming",
    customer_allows_incoming_calls:     "Customer allows Incoming calls",
    customer_allows_outgoing_calls:     "Customer allows Outgoing calls",
    block_premium_calls:                "Block Premium Calls",
    operator_barring_incoming_calls:    "Operator Barring of Incoming calls+SMS",
    block_premium_sms:                  "Block Premium SMS",
    operator_barring_outgoing_calls:    "Operator Barring of Outgoing calls+SMS",
    outgoing_international_barred:      "Outgoing International Barred",

    clip_clir:                          "CLIP/CLIR",
    call_forwarding:                    "Call Forwarding",
    calling_line_id_presentation:       "CLIP (Calling Line Identification Presentation)",
    calling_line_id_restriction:        "CLIR (Calling Line Identification Restriction)",
    visible:                            "Visible",
    invisible:                          "Invisible",

    sim_settings_labels: {
        AutoRoamingCharge: {
            label: "Auto Roaming Charge",
            help: {
                title: "Auto Roaming Charge",
                description: "Automatically charge your daily roaming charges when you travel abroad",
                functionalities: [
                    { value: "On", description: "We will automatically charge your account balance for daily roaming" },
                    { value: "Off", description: "We will require your approval via SMS to activate your daily roaming" },
                ],
            },
        },

        CLIP: {
            label: "CLIP (Calling Line Identification Presentation)",
            help: {
                title: "CLIP",
                description: "Turn your caller ID to visible or invisible on outgoing calls",
                functionalities: [
                    { value: "Visible", description: "Outgoing calls will include your CallerID" },
                    { value: "Invisible", description: "Outgoing calls will NOT include your CallerID" },
                ],
            },
        },
        CLIR: {
            label: "CLIR (Calling Line Identification Restriction)",
            help: {
                title: "CLIR",
                description: "Turn on caller ID so you can see CallerID on incoming calls",
                functionalities: [
                    { value: "On", description: "Incoming calls will show CallerID" },
                    { value: "Off", description: "Incoming calls will NOT show CallerIDs" },
                ],
            },
        },

        CallForwarding: {
            label: "Call Forwarding",
            help: {
                title: "Call Forwarding",
                description: "Turning this OFF will turn all the call forwarding options below OFF. Turning this back on will enable you to configure the below options.",
                functionalities: [
                    { value: "On", description: "User can change any of the below options" },
                    { value: "Off", description: "Turns off ALL the options below" },
                ],
            },
        },
        CFU: {
            label: "Call Forward Unconditional (CFU)",
            destination: "CFU Destination",
            help: {
                title: "Call Forward Unconditional",
                description: "Automatically and immediately forward calls always.",
                functionalities: [
                    { value: "On", description: "Calls will automatically and immediately forward always" },
                    { value: "Off", description: "Calls will NOT automatically and immediately forward always" },
                    { value: "Destination", description: "Here is where you enter the number you wish the calls to forward to" },
                ],
            },
        },
        CFB: {
            label: "Call Forward on Busy (CFB)",
            destination: "CFB Destination",
            help: {
                title: "Call Forward on Busy",
                description: "Automatically forward calls when you are busy on the line.",
                functionalities: [
                    { value: "On", description: "Calls will forward when busy" },
                    { value: "Off", description: "Calls will NOT forward when busy" },
                    { value: "Destination", description: "Here is where you enter the number you wish the calls to forward to" },
                ],
            },
        },
        CFNRY: {
            label: "Call Forward on No Reply (CFNR)",
            destination: "CFNR Destination",
            ring_time: "CFNR Ring time (seconds)",
            help: {
                title: "Call Forward on No Reply",
                description: "Automatically forward calls when you do not pickup.",
                functionalities: [
                    { value: "On", description: "Calls will forward when user does not pickup" },
                    { value: "Off", description: "Calls will NOT forward when user does not pickup" },
                    { value: "Destination", description: "Here is where you enter the number you wish the calls to forward to" },
                ],
            },
        },
        CFNRC: {
            label: "Call Forward on Not Reachable(CFNRC)",
            destination: "CFNRC Destination",
            help: {
                title: "Call Forward on Not Reachable",
                description: "Automatically forward calls in the event your services are down.",
                functionalities: [
                    { value: "On", description: "Calls will forward when service is un-available" },
                    { value: "Off", description: "Calls will NOT forward when service is un-available" },
                    { value: "Destination", description: "User to set this" },
                ],
            },
        },

        OCSRoamingBarred: {
            label: "Block roaming",
            help: {
                title: "Block roaming",
                description: "Turn off Roaming",
                functionalities: [
                    { value: "On", description: "Your SIM will NOT be able to roam" },
                    { value: "Off", description: "Your SIM WILL be able to roam" },
                ],
            },
        },
        OCSInCallsWhileRoamingBarred: {
            label: "Block Inbound Calls while Roaming",
            help: {
                title: "Block inbound calls while roaming",
                description: "Block inbound calls when SIM is roaming",
                functionalities: [
                    { value: "On", description: "Your SIM will NOT receive inbound calls" },
                    { value: "Off", description: "Your SIM WILL receive inbound calls" },
                ],
            },
        },
        OCSOutCallsWhileRoamingBarred: {
            label: "Block Outbound Calls while Roaming",
            help: {
                title: "Block outbound calls while roaming",
                description: "Enable user to make outbound calls while roaming",
                functionalities: [
                    { value: "On", description: "You CANNOT make outbound calls while roaming" },
                    { value: "Off", description: "You CAN make outbound calls while roaming" },
                ],
            },
        },
        OCSOOBWhileRoamingBarred: {
            label: "Block Out-of-Bundle calls while Roaming",
            help: {
                title: "Block out of bundle calls while roaming",
                description: "Blocks user from using OOB calls while roaming",
                functionalities: [
                    { value: "On", description: "You CANNOT make OOB calls" },
                    { value: "Off", description: "You CAN make OOB calls" },
                ],
            },
        },
        OCSBlockPremiumCalls: {
            label: "Block Premium Calls",
            help: {
                title: "Block Premium calls",
                description: "Blocks premium Calls",
                functionalities: [
                    { value: "On", description: "User CANNOT send premium Calls" },
                    { value: "Off", description: "User CAN send premium Calls" },
                ],
            },
        },
        OCSBlockPremiumSMS: {
            label: "Block Premium SMS",
            help: {
                title: "Block Premium SMS",
                description: "Blocks premium SMS",
                functionalities: [
                    { value: "On", description: "You CANNOT send premium SMS" },
                    { value: "Off", description: "You CAN send premium SMS" },
                ],
            },
        },
        OCSOutgoingIDDBarred: {
            label: "Outgoing International Barred",
            help: {
                title: "Outgoing international barred",
                description: "Blocks international outgoing calls",
                functionalities: [
                    { value: "On", description: "You CANNOT make outgoing international calls" },
                    { value: "Off", description: "You CAN make outgoing international calls" },
                ],
            },
        },
        AllowSMSRecieve: {
            label: "Customer allows Incoming SMS",
            help: {
                title: "Customer allows incoming SMS",
                description: "Enables incoming SMS",
                functionalities: [
                    { value: "On", description: "Your SIM WILL get incoming SMS" },
                    { value: "Off", description: "Your SIM will NOT receive incoming SMS" },
                ],
            },
        },
        AllowSMSSend: {
            label: "Customer allows Outgoing SMS",
            help: {
                title: "Customer allows outgoing SMS",
                description: "Enables Outgoing SMS",
                functionalities: [
                    { value: "On", description: "You CAN send SMS" },
                    { value: "Off", description: "You CANNOT send SMS" },
                ],
            },
        },
        AllowIncomingCalls: {
            label: "Customer allows Incoming calls",
            help: {
                title: "Customer allows incoming calls",
                description: "Enables incoming calls",
                functionalities: [
                    { value: "On", description: "You CAN receive calls" },
                    { value: "Off", description: "You CANNOT receive calls" },
                ],
            },
        },
        AllowOutgoingCalls: {
            label: "Customer allows Outgoing calls",
            help: {
                title: "Customer allows outgoing calls",
                description: "Enables outgoing calls",
                functionalities: [
                    { value: "On", description: "You CAN make outgoing calls" },
                    { value: "Off", description: "You CANNOT make outgoing calls" },
                ],
            },
        },
        BarIncomingCalls: {
            label: "Operator Barring of Incoming calls+SMS",
            help: {
                title: "Operating barring of incoming calls + SMS",
                description: "Overrides any setting above and bars incoming calls and SMS",
                functionalities: [
                    { value: "On", description: "Customer cannot receive incoming calls + SMS" },
                    { value: "Off", description: "Customer can use what SUPP settings they have configured" },
                ],
            },
        },
        BarOutgoingCalls: {
            label: "Operator Barring of Outgoing calls+SMS",
            help: {
                title: "Operator barring outgoing calls + SMS",
                description: "Overrides any setting above and bars outgoing calls and SMS",
                functionalities: [
                    { value: "On", description: "Customer cannot make outgoing calls + SMS" },
                    { value: "Off", description: "Customer can use what SUPP settings they have configured" },
                ],
            },
        },

        AllowConferencing: {
            label: "3 Way Conferencing",
            help: {
                title: "3 way conferencing",
                description: "Ability to have 3 people on the same call",
                functionalities: [
                    { value: "On", description: "You CAN add a 3rd line to your calls" },
                    { value: "Off", description: "You CANNOT add a 3rd line to your calls" },
                ],
            },
        },

        CallWaiting: {
            label: "Call Waiting",
            available: "Call waiting Available",
            enabled: "Call waiting Active",
            help: {
                title: "Call waiting",
                description: "Ability for call waiting",
                functionalities: [
                    { value: "On", description: "You CAN see if an incoming call is waiting whilst on an existing call" },
                    { value: "Off", description: "You CANNOT see if an incoming call is waiting whilst on an existing call" },
                ],
            },
        },
        AllowCallHold: {
            label: "Allow call hold",
            help: {
                title: "Allow call hold",
                description: "Ability to put calls on hold",
                functionalities: [
                    { value: "On", description: "You CAN put calls on hold" },
                    { value: "Off", description: "You CANNOT put calls on hold" },
                ],
            },
        },
    },

    unconditional:                      "Unconditional",
    on_busy:                            "on Busy",
    on_no_reply:                        "on No Reply",
    on_not_reachable:                   "on Not Reachable",

    cfu:                                "CFU",
    cfb:                                "CFB",
    cfnr:                               "CFNR",
    cfnrc:                              "CFNRC",
    active:                             "Active",
    inactive:                           "Inactive",
    destination:                        "Destination",
    time:                               "Time",
    duration:                           "Duration",
    cfnr_ring_time:                     "CFNR Ring time (seconds)",

    // onboarding new flow
    select_product_for:                 "Select product for ",
    not_activated:                      "not activated",
    not_active:                         "not active",
    held:                               "held",

    dob:                                "DOB",
    postal_address:                     "Postal Address",
    proof_of_postal_address:            "Proof of postal address",
    drivers_license_or_passport:        "Drivers License # or Passport #",
    proof_of_license_passport:          "Proof of license/passport",

    postpay_credit_check:               "Postpay Credit Check",
    credit_check_sucessful:             "Credit check is sucessful",
    successfully_passed_credit_checking:"You have successfully passed our credit checking, please click next to proceed",
    credit_check_failed:                "Credit check failed",
    not_passed_credit_checking:         "You have NOT passed our credit checking, please contact X to find out more or change to prepay or PAYG",

    we_need_authorize_credit_card:      "We need to authorize your credit card to ensure there are funds.",
    you_will_not_be_charged:            "You will not be charged",
    back:                               "Back",
    card_is_sucessfully_authorized:     "Card is sucessfully authorized",
    wait_we_activate_your_products:     "Your card has been successfully authorized, please wait while we activate your products",
    card_authorization_error:           "Card authorization error",
    we_unable_authorize_your_card:      "Sorry we were unable to authorize your card, please check with your card supplier or try another card",
    ordering_error:                     "Ordering Error",
    we_unable_process_your_order:       "Sorry we we unable to process your order, because xxxx",
    activate_error:                     "Activate Error",
    we_unable_activate_your_sim:        "Sorry we we unable to activate your SIM, please call xxxx",
    casual:                             "Casual",
    prepay_skip_casual:                 "Prepay (Skip/Casual)",
    casual_warning:                     "Casual Warning",
    be_aware_you_will_be_charged:       "Please be aware you will be charged for all data",

    // postpay product
    you_dont_have_postpay_product:      "You don't have postpay product",
    plan:                               "Plan",
    current_plan:                       "Current Plan",
    current_next_new_plan:              "New Plan",
    retired:                            "Retired",
    switch_to_this_plan:                "Switch to this plan",
    change_to_this_addon:               "Change to this addon",
    change_plan_options:                "Change plan options",
    change_plan:                        "Change plan",
    when_would_you_like_change_plan:    "When would you like your change plan to occur?",
    now:                                "Now",
    at_next_billing_period:             "At next billing period",
    at_next_billing_date:               "At next billing date",
    purchase_this_addon:                "Purchase this addon",
    purchase_this_plan:                 "Purchase this plan",
    subs:                               "SUBS",
    unsubscribe:                        "Unsubscribe",

    // prepay product
    my_addons:                          "My Addons",

    going_to_charge_missing_balance:    "Your balance is ${balance_is}. We are going to charge missing ${missing_balance_is} from your card. Please choose card.",
    minimum_charging_amount_is:         "Minimum charging amount is $1.00",

    // Invoices
    invoices:                           "Invoices",
    invoice:                            "Invoice",
    bill_cycle:                         "Bill Cycle:",
    change_your_bill_cycle:             "Change your bill cycle",
    change_your_bill_cycle_note:        "Please note, if you change your bill cycle, any outstanding bills will be closed and marked as due. After changing this, your next bill will take on the new cycle.",
    bill_me_every:                      "Bill me every:",
    seven_days:                         "7 Days",
    twenty_eight_days:                  "28 Days",
    this_period_charges:                "This period charges",
    you_dont_have_charges:              "You don't have any charges during this period",
    history:                            "History",
    period:                             "Period",
    includes_gst:                       "Includes GST of",
    quantity:                           "Quantity",
    rate:                               "Rate",
    charge:                             "Charge",
    your_plan_charges:                  "Your plan charges",
    extras:                             "Extras",
    GST_number:                         "GST Number",
    grand_total:                        "Grand Total",

    PAID:                               "PAID",
    DUE:                                "DUE",
    OVERDUE:                            "OVERDUE",
    VOID:                               "VOID",

    purchases:                          "Purchases",
    payments:                           "Payments",
    discount:                           "Discount",
    remaining_amount:                   "Remaining amount",
    amount_remaining:                   "Amount remaining",
    created_at:                         "Created At",
    back_to_all_invoices:               "Back to all invoices",
    total:                              "Total",
    invoice_date:                       "Invoice date",
    due_date:                           "Due date",
    download_pdf:                       "Download PDF",
    no_uuid:                            "No UUID",
    not_allowed:                        "Not Allowed",
    no_invoice_number:                  "No invoice number",
    no_receipt_uuid:                    "No receipt UUID",
    file_name_not_specified:            "File name is not specified",
    file_downloading_error:             "File downloading error",
    product:                            "Product",
    amount:                             "Amount",
    no_invoices:                        "No invoices to show",
    cant_get_invoice:                   "We can't get Invoice right now",
    cant_get_invoices:                  "We can't get Invoices right now",
    no_charges:                         "No charges for current period",
    invoice_not_found:                  "The invoice #{number} Not Found",
    payment_details_remittance_advice:  "Payment Details / Remittance Advice",
    receipt_details_lead:               `Thank you for using ${process.env.VUE_APP_NAME?.split(' ')[0] || 'our service'}, we hope you have enjoyed your services.`,
    bank_account_name:                  "Bank Account Name",
    bank_account_number:                "Bank Account Number",
    reference_code:                     "Reference Code",
    reference_your_payment:             "Payment to the nominated bank account is supported, please reference your payment as follows:",
    particulars:                        "Particulars",
    reference:                          "Reference",
    code:                               "Code",

    left:                               "left",
    used:                               "used",
    
    // Receipts
    receipts:                           "Receipts",
    receipt:                            "Receipt",
    back_to_all_receipts:               "Back to all receipts",
    receipt_date:                       "Receipt date",
    no_receipts:                        "No receipts to show",
    cant_get_receipts:                  "We can't get Receipts right now",
    receipt_not_found:                  "The receipt #{number} Not Found",

    // Postpay Plan
    unlimited:                          "Unlimited",
    casual_rate:                        "Casual Rate",
    bonus_data:                         "Bonus Data",
    bonus_voice:                        "Bonus Voice",
    bonus_SMS:                          "Bonus SMS",
    bonus_MMS:                          "Bonus MMS",

    to_NZ_and_AU:                       "to NZ & AU",
    max_spid:                           "Max speed",
    up_to_bps:                          "up to {speedLimitDL}bps",

    renewable_period:                   "Renewable Period",
    service_period:                     "Service Period",
    day:                                "day",
    days:                               "days",
    monthly:                            "monthly",
    monthly_upper:                      "Monthly",
    daily_upper:                        "Daily",
    yearly_upper:                       "Yearly",

    // Carousel filter
    all:                                "All",

    // Purchase SIM
    SIM:                                "SIM",
    purchase_sim:                       "Purchase SIM",
    real_sim:                           "Physical SIM",
    e_sim:                              "ESIM",
    already_have_sim:                   "I already have a SIM",
    already_have_sim_provider:          `I already have a ${ process.env.VUE_APP_MIDDLE_NAME || '' } SIM`,
    already_have_sim_subtitle:          "If you have already obtained a SIM card and want to signup to put a plan on it.",
    zip_code:                           "ZIP-code",
    purchase:                           "Purchase",
    sim_card_type:                      "SIM Card type",
    shipping_address:                   "Shipping address",


    order_details:                      "Order details",
    order_tracking:                     "Order Tracking",
    track_order:                        "Track Order",
    confirmed:                          "CONFIRMED",
    paid:                               "PAID",
    complited:                          "COMPLETED",
    partially_shipped:                  "PARTIALLY",
    feiled:                             "FAILED",
    canceled:                           "CANCELED",
    cart:                               "CART",
    delivered:                          "DELIVERED",
    shipped:                            "SHIPPED",
    processing:                         "PROCESSING",
    unknown:                            "UNKNOWN",

    you_have_bought:                    "You have bought",
    SIM_Card:                           "SIM Card",
    SIM_Cards:                          "SIM Cards",
    checking_eSIM_Data:                 "Checking eSIM Data",
    and_a_new_sim_card_for:             "and a new SIM Card for",
    we_will_ship:                       "We will ship your new SIM to",
    you_will_receive_email:             "and you will receive an email confirming your purchase details shortly.",
    you_have_selected_when_i_get_sim:   "You have selected to choose your plan when you get your SIM",
    simply_put_sim_in_your_phone:       "Simply put your SIM in your phone when you receive it to continue.",
    close:                              "Close",

    sim: {
        ...theme_locales.sim,

        shipping_address_step: {
            title: "Enter your shipping address",
        },
        credit_card_step: {
            title: "Pay with a credit card",
        },
        check_information_step: {
            title: "Please check the entered information",
        },
        select_when_get_sim_step: {
            title: "Your selection is successful",
        },
    },

    setting_the_order:                 "Setting the order",
    please_choose_action:              "Please choose your SIM",

    sim_transfer_product_type: {
        select_product_type: {
            title: "Product type transfer",
            subtitle: "Changing your mobile account type"
        },
        pay_off_the_debt: {
            title: "Pay off the debt to be able to transfer the product type",
            subtitle: ""
        },
        success: {
            title: "Success",
            subtitle: ""
        },
    },
    successfully_paid_off_debt: 'You’ve successfully paid off outstanding debt. Now you can continue your billing type transfer',
    sim_transfer_product_message: 'Seems like payment type has been changed for your current plan. Interface will be refreshed after this modal is closed.',

    // Broadband
    location:                           "Location",
    package:                            "Package",
    summary:                            "Summary",
    checkout:                           "Checkout",
    choose_your_location:               "Choose your location",
    broadband_service_address:          "Broadband Service Address",
    service_availability:               "Service Availability",
    dwelling_type:                      "Dwelling Type",
    total_per_month:                    "Total Per Month",
    confirm_payment:                    "Confirm payment",
    change_package:                     "Change package",
    view_my_usage:                      "View my usage",
    additional:                         "Additional",
    add_new_method:                     "Add new method",
    network_usage:                      "Network usage",

    // International plans
    international_plans:                "International calling",
    oob_voice_call_charges:             "OOB voice call charges",

    australia:                          "Australia",
    bangladesh:                         "Bangladesh",
    canada:                             "Canada",
    china:                              "China",
    cook:                               "Cook Islands",
    fiji:                               "Fiji",
    french_polynesia:                   "Fr Polynesia",
    hawaii:                             "Hawaii",
    hong_kong:                          "Hong Kong",
    india:                              "India",
    macau:                              "Macau",
    malaysia:                           "Malaysia",
    nepal:                              "Nepal",
    niue:                               "Niue",
    pakistan:                           "Pakistan",
    samoa:                              "Samoa",
    singapore:                          "Singapore",
    sri_lanka:                          "Sri Lanka",
    taiwan:                             "Taiwan",
    tonga:                              "Tonga",
    uae:                                "UAE",
    uk:                                 "UK",
    usa:                                "USA",
    vietnam:                            "Vietnam",

    c_min:                              "c / min",
    back_to_plans:                      "Back to {plan} Plans",
    back_to_addons:                     "Back to Addons",
    back_to_dashboard:                  "Back to Dashboard",

    // network services map
    check_your_coverage:                "Check your coverage",
    enter_your_location:                "Enter your location",
    error_accessing_ArcGIS_services:    "Error accessing ArcGIS services.",
    error_while_loading_google_maps:    "An error occurred while loading Google Maps",
    response_error_accessing_ArcGIS:    "Response error accessing ArcGIS services.",

    // eshop
    catalog:                            "Catalog",

    // Suspended
    pay_off_the_debt:                   "Pay off the debt to be able to continue using the account",
    successfully_paid_off_the_debt:     "You have successfully paid off the debt and can continue to use your account.",
    you_dont_have_any_payment_method:   "You don't have any payment method yet",
    to_profile:                         "To profile",

    // No Payment Methods
    you_dont_have_any_payment_methods_connected: `You don’t have any payment methods connected. Please add a payment card to continue using ${process.env.VUE_APP_NAME ?? 'Our services'}`,

    // No Addons Page
    you_have_no_active_plans:           "You have no active plans",
    Please_choose_a_plan_to_proceed_with: "Please choose a plan to proceed with",
    you_successfully_purchased_plan:    "You have successfully purchased a plan and can continue using your account.",

    // Discount and Offer Credit on Services
    we_will_add_this_amount_to_your_balance: "We will add this amount to your balance after <b>this {plan_or_addon}</b> is purchased",
    your_discount_for_current_plan_is:  "Your discount for <b>this {plan_or_addon}</b> is ${discount}",
    special_offer_details:              "Special offer details",
    price:                              "Price",
    credit:                             "Credit",
}
